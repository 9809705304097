<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
        <v-card id="card-custom" class="px-2 py-2 rounded-lg">
          <v-card-actions>
            <v-btn icon @click="$router.back()">
              <v-icon>keyboard_arrow_left</v-icon>
            </v-btn>
            <p class="headline-color px-2 mt-4">Profile Admin</p>
          </v-card-actions>
          <v-divider class="mb-5" />
          <v-form
            id="form"
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col cols="5" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Username</v-subheader
                >
              </v-col>
              <v-col cols="7" class="py-1">
                <v-text-field
                  v-model="form.username"
                  disabled
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Level</v-subheader
                >
              </v-col>
              <v-col cols="7" class="py-1">
                <v-text-field
                  v-model="form.role"
                  disabled
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-5 pt-3 px-7">
                <span class="caption">Kata Sandi: </span>
                <span
                  class="caption"
                  :class="isChange ? 'hover-error' : 'hover-accent'"
                  @click="handleReset"
                  >{{
                    isChange ? "Cancel Request" : "Request Reset Password"
                  }}</span
                >
              </v-col>
            </v-row>
            <template v-if="isChange">
              <v-row>
                <v-col cols="5" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >Password Lama</v-subheader
                  >
                </v-col>
                <v-col cols="7" class="py-1">
                  <v-text-field
                    outlined
                    dense
                    :append-icon="e2 ? 'visibility' : 'visibility_off'"
                    @click:append="() => (e2 = !e2)"
                    :type="e2 ? 'password' : 'text'"
                    :rules="[v => !!v || 'Password Lama harus diisi']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >Password Baru</v-subheader
                  >
                </v-col>
                <v-col cols="7" class="py-1">
                  <v-text-field
                    v-model="form.password"
                    outlined
                    dense
                    :append-icon="e0 ? 'visibility' : 'visibility_off'"
                    @click:append="() => (e0 = !e0)"
                    :type="e0 ? 'password' : 'text'"
                    :rules="[v => !!v || 'Password Baru harus diisi']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >Ulangi Password</v-subheader
                  >
                </v-col>
                <v-col cols="7" class="py-1">
                  <v-text-field
                    v-model="form.password_confirmation"
                    outlined
                    dense
                    :append-icon="e1 ? 'visibility' : 'visibility_off'"
                    @click:append="() => (e1 = !e1)"
                    :type="e1 ? 'password' : 'text'"
                    :rules="confirmPasswordRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <v-card-actions class="mt-3">
              <v-spacer />
              <v-btn
                small
                color="#FBB005"
                class="px-12 subtitle-2 text-capitalize"
                :loading="formLoading"
                type="submit"
                :disabled="!valid"
                form="form"
                >Simpan</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import UserService from "@/services/resources/user.service";

export default {
  data() {
    return {
      formLoading: false,
      valid: false,
      isChange: false,
      e0: true,
      e1: true,
      e2: true,
      confirmPasswordRules: [
        v => !!v || "Konfirmasi Password Baru harus diisi",
        v => (v && v == this.form.password) || "Password Baru harus sesuai"
      ],
      form: {
        id: null,
        username: null,
        name: null,
        password: null,
        password_confirmation: null,
        role: null,
        kode_device: null,
        unit_kerja_2_id: null
      }
    };
  },
  methods: {
    handleReset() {
      this.form.password = null;
      this.form.password_confirmation = null;
      this.isChange = !this.isChange;
    },
    save() {
      if (this.$refs.form.validate()) {
        let payload = {
          id: this.form.id,
          username: this.form.username,
          name: this.form.name,
          password: this.form.password || null,
          password_confirmation: this.form.password_confirmation || null,
          role: this.form.role,
          kode_device: this.form.kode_device,
          unit_kerja_2_id: this.form.unit_kerja_2_id
        };
        this.saveUser(payload);
      }
    },
    // Service
    async getDetailUser() {
      try {
        this.loading = true;
        await UserService.getDetail(1)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.form = {
                id: data.id,
                username: data.username,
                name: data.name,
                password: data.password,
                password_confirmation: data.password_confirmation,
                role: data.role,
                kode_device: data.kode_device,
                unit_kerja_2_id: data.unit_kerja_2_id
              };
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveUser(data) {
      try {
        this.formLoading = true;
        await UserService.save(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getDetailUser();
              this.isChange = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getDetailUser();
  }
};
</script>
<style lang="scss">
#card-custom .v-input {
  font-size: 0.775rem;
}
#card-custom .v-input {
  &.v-input--is-disabled {
    background-color: #f0f0f7;
  }
}
</style>
