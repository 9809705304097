import BaseInstance from "../base.instance";
import {
  USER_LIST,
  USER_LIST_ROLE,
  USER_DETAIL,
  USER_SAVE,
  USER_DELETE
} from "../constants";

const UserService = {
  getList(data) {
    return BaseInstance.post(USER_LIST, data);
  },
  getListRole(params) {
    return BaseInstance.query(USER_LIST_ROLE, { params });
  },
  getDetail(id) {
    return BaseInstance.fetch(USER_DETAIL, id);
  },
  save(data) {
    return BaseInstance.post(USER_SAVE, data);
  },
  delete(id) {
    return BaseInstance.deleteSlug(USER_DELETE, id);
  }
};

export default UserService;
